body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* Set the font size of all cells in the table to 14 pixels */
.ant-table td {
  font-size: 14px;
}

/* Set the font size of all header cells in the table to 16 pixels */
.ant-table-thead th {
  font-size: 16px;
}

/* Set the background color of the table to light gray */

/* Add a border to all cells in the table */

.table-container {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-left: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.responsive-table .ant-table {
  font-size: 10px;
}

.responsive-table .ant-table-thead > tr > th{
  padding: 3px 4px;
  background-color: "white";
  color: #7cb305;
  font-weight: bold;
  font-size: "16px";
}

.responsive-table .ant-table-thead > tr > td {
  padding: 3px 4px;
  background-color: "white";
  color: #7cb305;
  font-weight: bold;
  font-size: "16px";
}
.responsive-table .ant-table-tbody > tr > td {
  padding: 4px 4px;
}

.leader-table-container {
  padding-top: 10px;
  padding-right: 20px;
  padding-bottom: 50px;
  padding-left: 20px;
  max-width: 100%;
  overflow-x: auto;
}

.responsive-leader-table .ant-table {
  font-size: 10px;
}

.responsive-leader-table .ant-table-thead > tr > th{
  padding: 3px 4px;
  background-color: "white";
  color: black;
  font-weight: bold;
  font-size: "8px";
}

.responsive-leader-table .ant-table-thead > tr > td {
  padding: 3px 4px;
  background-color: "white";
  color: black;
  font-weight: bold;
  font-size: "8px";
}
.responsive-leader-table .ant-table-tbody > tr > td {
  padding: 4px 4px;
}
/* Hide the border on cells in the table */


/* Apply different styles for smaller screens */
@media only screen and (max-width: 768px) {
  .responsive-table .ant-table {
    font-size: 10px;
  }

  .responsive-table .ant-table-thead > tr > th{
    padding: 3px 4px;
    background-color: "white";
    color: #7cb305;
    font-weight: bold;
    font-size: "16px";
  }

  .responsive-table .ant-table-thead > tr > td {
    padding: 3px 4px;
    background-color: "white";
    color: #7cb305;
    font-weight: bold;
    font-size: "16px";
  }
  .responsive-table .ant-table-tbody > tr > td {
    padding: 4px 4px;
  }
  /* Hide the border on cells in the table */

  .responsive-table .table-container {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
    max-width: 100%;
    overflow-x: auto;
  }
}


@media only screen and (max-width: 768px) {
  .responsive-leader-table .ant-table {
    font-size: 10px;
  }

  .responsive-leader-table .ant-table-thead > tr > th{
    padding: 3px 4px;
    background-color: "white";
    color: black;
    font-weight: bold;
    font-size: "8px";
    line-height: 2;
  }

  .responsive-leader-table .ant-table-thead > tr > td {
    padding: 3px 4px;
    background-color: "white";
    color: black;
    font-weight: bold;
    font-size: "8px";
    line-height: 2;
  }
  .responsive-leader-table .ant-table-tbody > tr > td {
    padding: 4px 4px;
  }
  /* Hide the border on cells in the table */

  .responsive-leader-table .table-container {
    padding-top: 10px;
    padding-right: 20px;
    padding-bottom: 50px;
    padding-left: 20px;
    max-width: 100%;
    overflow-x: auto;
  }
}